<template>
  <div class="mt-5 mb-4">
    <hr>
    <div class="item-top-area d-flex align-items-center">
      <div class="item-right mr-auto">
        <div class="switch-toggle">
          <span>🗃️ RSS feed posts count</span>
        </div>
      </div>
    </div>
    <div class="mt-3 input_icon_text d-flex align-items-center">
      <div class="content">
        <div class="url">Card number: {{cardNumberObj.card_number}}</div>
        <div class="name">
          <div class="validation_input">
            <input type="range" v-tooltip="cardNumberObj.card_number" min="0" v-model="cardNumberObj.card_number" max="25"
                   value="5"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data () {
    return {
      cardNumberObj: {
        card_number: this.card_number
      }
    }
  },
  created () {
    this.cardNumberObj.card_number = this.card_number
  },
  props: [
    'card_number'
  ],
  watch: {
    'cardNumberObj.card_number' () {
      this.$emit('cardNumberObj', this.cardNumberObj)
    }
  }

})
</script>
